<template>
  <section>
    <AuthWrapper>
      <template #card>
        <div>
          <div class="text-center my-2">
            <h2>Sorry, No Registration Closed</h2>
          </div>

          <div class="text-center my-2">
            <b-img src="@/assets/images/public/no-signups.png" height="80" width="80" fluid alt="Fluid image"></b-img>
          </div>

          <div class="text-center">
            <p>
              Sorry for the inconvenience but we are currently not accepting any new signups, Please try again later
            </p>
          </div>

          <div class="text-center">
            <b-link
                :to="{ name: 'app-root'}"
            >
              <feather-icon icon="ChevronLeftIcon" />
              Go back to Home
            </b-link>

          </div>

        </div>
      </template>
    </AuthWrapper>
  </section>
</template>

<script>
import { BImg, BLink } from "bootstrap-vue";
import AuthWrapper from "@core/components/auth-wrapper/AuthWrapper.vue";

export default {
  name: "NoSignUps",
  components: { AuthWrapper, BImg, BLink }
}
</script>

<style scoped>

</style>
