<template>
  <section>
    <div class="auth-wrapper auth-v2">
      <div class="auth-inner">
        <div class="wrapper">
          <div class="central-card-design">
            <!-- <Rectangles /> -->
            <div class="central-card px-md-5 px-2 py-3 rounded-lg shadow">

              <!--  card slot -->
              <slot name="card">
              </slot>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { BOverlay, BLink } from "bootstrap-vue";
import BottomDesign from '@/assets/svg/BottomDesign.svg';
import AppLogo from '@core/layouts/components/Logo.vue';

export default {
  name: "AuthWrapper",
  components: {
    BLink,
    AppLogo,
    BOverlay,
    BottomDesign,
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
